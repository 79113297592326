import React from 'react'
import { useFormik } from "formik";
import axios from 'axios'

export function SignInPage() {
  const formik = useFormik({
    initialValues: {username: "", password: ""},
    // validationSchema: LoginSchema,
    onSubmit: async (values) => {
      console.log(values)
      axios.post("https://usermoduleapiv1prod.auth.us-east-1.amazoncognito.com/login?client_id=34h5n5a2u306m9vok79cisi4j3&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://auth.singlelogin.io/admin/index", values)
      .then(response=>console.log("response "+response))
      .catch(error=>console.log("error"+error))
    },
  })
    return (
        <>
        {/* <!-- CONTENT --> */}
        <section className="section-border border-primary">
            <div className="container d-flex flex-column">
              <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
      
                  {/* <!-- Heading --> */}
                  <h1 className="mb-0 fw-bold text-center">
                    Sign in
                  </h1>
      
                  {/* <!-- Text --> */}
                  <p className="mb-6 text-center text-muted">
                    Simplify your workflow in minutes.
                  </p>
      
                  {/* <!-- Form --> */}
                  <form className="mb-6" onSubmit={formik.handleSubmit}>
      
                    {/* <!-- Email --> */}
                    <div className="form-group">
                      <label className="form-label">
                        Email Address
                      </label>
                      <input type="text" className="form-control" id="username" placeholder="username" 
                        {...formik.getFieldProps("username")}/>
                    </div>
      
                    {/* <!-- Password --> */}
                    <div className="form-group mb-5">
                      <label className="form-label">
                        Password
                      </label>
                      <input type="password" className="form-control" id="password" placeholder="Enter your password" 
                        {...formik.getFieldProps("password")}/>
                    </div>
      
                    {/* <!-- Submit --> */}
                    <button className="btn w-100 btn-primary" type="submit">
                      Sign in
                    </button>
      
                  </form>
      
                  {/* <!-- Text --> */}
                  <p className="mb-0 fs-sm text-center text-muted">
                    Don't have an account yet <a href="signup.html">Sign up</a>.
                  </p>
      
                </div>
              </div> {/* <!-- / .row --> */}
            </div> {/* <!-- / .container --> */}
          </section>
      
        </>
    )
}