import React from 'react'
import { Link } from 'react-router-dom'

export function Footer() {
	return (
		<>
			{/* <!-- SHAPE --> */}
			<div className='position-relative'>
				<div className='shape shape-bottom shape-fluid-x text-gray-200'>
					<svg
						viewBox='0 0 2880 48'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z'
							fill='currentColor'
						/>
					</svg>
				</div>
			</div>

			{/* <!-- FOOTER --> */}
			<footer className='py-8 py-md-11 bg-gray-200'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-md-4 col-lg-3'>
							{/* <!-- Brand --> */}
							<img
								src='./assets/img/logo-blue.png'
								alt='...'
								className='footer-brand img-fluid mb-2'
							/>

							{/* <!-- Text --> */}
							<p className='text-gray-700 mb-2'>Business in a Box.</p>

							{/* <!-- Social --> */}
							<ul className='list-unstyled list-inline list-social mb-6 mb-md-0'>
								<li className='list-inline-item list-social-item me-3'>
									<Link to='/schedule-demo' className='text-decoration-none'>
										<img
											src='./assets/img/icons/social/instagram.svg'
											className='list-social-icon'
											alt='...'
										/>
									</Link>
								</li>
								<li className='list-inline-item list-social-item me-3'>
									<Link to='/schedule-demo' className='text-decoration-none'>
										<img
											src='./assets/img/icons/social/facebook.svg'
											className='list-social-icon'
											alt='...'
										/>
									</Link>
								</li>
								<li className='list-inline-item list-social-item me-3'>
									<Link to='/schedule-demo' className='text-decoration-none'>
										<img
											src='./assets/img/icons/social/twitter.svg'
											className='list-social-icon'
											alt='...'
										/>
									</Link>
								</li>
								<li className='list-inline-item list-social-item'>
									<Link to='/schedule-demo' className='text-decoration-none'>
										<img
											src='./assets/img/icons/social/pinterest.svg'
											className='list-social-icon'
											alt='...'
										/>
									</Link>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Pages</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-6 mb-md-8 mb-lg-0'>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Pricing
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Help Center
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Schedule a Demo
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Why Single Login
									</Link>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Services</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-6 mb-md-8 mb-lg-0'>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Enterprise
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Business
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Developers
									</Link>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>
								Applications
							</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-0'>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Timesheet
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										eLearning
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Expense App
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Quiz App
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Scheduling
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										SalesBook
									</Link>
								</li>
								<li>
									<Link to='/schedule-demo' className='text-reset'>
										Events
									</Link>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Legal</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-0'>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Documentation
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										Versions
									</Link>
								</li>
								<li className='mb-3'>
									<Link to='/schedule-demo' className='text-reset'>
										API Status
									</Link>
								</li>
							</ul>
						</div>
					</div>{' '}
					{/* <!-- / .row --> */}
				</div>{' '}
				{/* <!-- / .container --> */}
			</footer>
		</>
	)
}
