import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "./components/SplashScreen";
import {Navbar} from './components/Navbar'
import {Footer} from './components/Footer'
import {LandingPage} from './pages/LandingPage'
import {SignInPage} from './pages/SignInPage'
import {PricingPage} from './pages/PricingPage'
import {DemoPage} from './pages/DemoPage'

export function Routes() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <Navbar />
      <Switch>
        <Route path="/signin" component={SignInPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/schedule-demo" component={DemoPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
      <Footer />
    </Suspense>
  );
}
