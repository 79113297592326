import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import swal from 'sweetalert'
// import {apps} from '../data/apps'

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: "",
  business_name: '',
  employees: ''
}

const LeadSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last Name is required'),
  business_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Business Name is required'),
  employees: Yup.string()
    .required('Number of employees is required'),
  phone: Yup.string(),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    .required('Enter a valid email'),
})
export function Form() {
  const [loading, setLoading] = useState(false)

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: LeadSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      axios
        .post(API_URL + '/lead', { lead: values })
        .then(() => {
          setLoading(false)
          swal('Congrats!', 'Details successfully recieved', 'success')
          resetForm()
        })
        .catch(({ response }) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(response)
          swal('Error!', response?.data?.message || 'Error', 'error')
        })
    },
  })
  return (
    <>
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">

          {/* <!-- First name --> */}
          <div className="form-floating">
            <input
              type="text"
              {...formik.getFieldProps('first_name')}
              className={`form-control form-control-flush ${getInputClasses('first_name')}`}
              id="registrationFirstName" 
              placeholder="First name"
            />
            <label>First name</label>
          </div>

        </div>
        <div className="col-12 col-md-6">

          {/* <!-- Last name --> */}
          <div className="form-floating">
            <input
              type="text"
              {...formik.getFieldProps('last_name')}
              className={`form-control form-control-flush ${getInputClasses('last_name')}`}
              id="registrationLastName" 
              placeholder="Last name"
            />
            <label>Last name</label>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">

          {/* <!-- Email --> */}
          <div className="form-floating">
            <input
              type="email"
              {...formik.getFieldProps('email')}
              className={`form-control form-control-flush ${getInputClasses('email')}`}
              id="registrationEmail" 
              placeholder="Email"
            />
            <label>Email</label>
          </div>

        </div>
        <div className="col-12 col-md-6">

          {/* <!-- Password --> */}
          <div className="form-floating">
            <input
              type="text"
              {...formik.getFieldProps('phone')}
              className={`form-control form-control-flush ${getInputClasses('phone')}`}
              id="registrationPhone" 
              placeholder="Phone number"
            />
            <label>Phone</label>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">

          {/* <!-- Email --> */}
          <div className="form-floating">
            <input
              type="text"
              {...formik.getFieldProps('business_name')}
              className={`form-control form-control-flush ${getInputClasses('business_name')}`}
              id="registrationEmail" 
              placeholder="Business Name"
            />
            <label>Business Name</label>
          </div>

        </div>
        <div className="col-12 col-md-6">

          {/* <!-- Password --> */}
          <div className="form-floating">
            <select className={`form-select form-control-flush ${getInputClasses('employees')}`} {...formik.getFieldProps('employees')}  aria-label="Default select example">
              <option className="text-muted">No. of Employees</option>
              <option value="1 - 10">1 - 10</option>
              <option value="11 - 20">11 - 20</option>
              <option value="21 - 50">21 - 50</option>
              <option value="51 - 100">51 - 100</option>
              <option value="101 - 1000">101 - 1000</option>
              <option value="1001 and above">1001 and above</option>
            </select>
            {/* <label>Phone</label> */}
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-12">

          {/* <!-- Submit --> */}
          <br/>
          <button type="submit" className="btn w-100 btn-primary mt-3 lift">
            {loading && (
              <span
                className='spinner-border spinner-border-sm mr-3'
                role='status'
                aria-hidden='true'
              ></span>
            )}
            Request a demo
          </button>

        </div>
      </div>
    </form>
    </>
  )
}
