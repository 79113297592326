import React from 'react'
import { Link } from 'react-router-dom'
import './CloseNavBar'

export function Navbar() {
	return (
		<nav className='navbar navbar-expand-lg navbar-light bg-white py-3'>
			<div className='container-fluid'>
				{/* <!-- Brand --> */}
				<a className='navbar-brand' href='/'>
					<img
						src='assets/img/logo-blue.png'
						className='navbar-brand-img'
						alt='...'
					/>
				</a>

				{/* <!-- Toggler --> */}
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarCollapse'
					aria-controls='navbarCollapse'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>

				{/* <!-- Collapse --> */}
				<div className='collapse navbar-collapse' id='navbarCollapse'>
					{/* <!-- Toggler --> */}
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarCollapse'
						aria-controls='navbarCollapse'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<i className='fe fe-x'></i>
					</button>
					{/* <!-- Navigation --> */}
					<ul className='navbar-nav ms-auto'>
						<li className='nav-item dropdown'>
							<a
								className='nav-link dropdown-toggle'
								id='navbarLandings'
								data-bs-toggle='dropdown'
								href='/#'
								role='menuitem'
								aria-haspopup='true'
								aria-expanded='false'
							>
								Why Single Login?
							</a>
							<div
								className='dropdown-menu dropdown-menu-xl p-0'
								aria-labelledby='navbarLandings'
							>
								<div className='row gx-0'>
									<div className='col-12 col-lg-6'>
										<div
											className='dropdown-img-start'
											style={{
												backgroundImage: 'url(./assets/img/photos/photo-3.jpg)',
											}}
										>
											{/* <!-- Heading --> */}
											<h4 className='fw-bold text-white mb-0'>
												Want to see how it works?
											</h4>

											{/* <!-- Text --> */}
											<p className='fs-sm text-white'>
												Get a 30 days free trial
											</p>

											{/* <!-- Button --> */}
											<Link
												to='/schedule-demo'
												className='btn btn-sm btn-white shadow-dark fonFt-size-sm'
											>
												Schedule a Demo
											</Link>
										</div>
									</div>
									<div className='col-12 col-lg-6'>
										<div className='dropdown-body'>
											<div className='row gx-0'>
												<div className='col-6'>
													{/* <!-- Heading --> */}
													<h6 className='dropdown-header'>Services</h6>

													{/* <!-- List --> */}
													<Link
														className='dropdown-item colll'
														to='/schedule-demo'
													>
														Enterprise
													</Link>
													<Link
														className='dropdown-item colll'
														to='/schedule-demo'
													>
														Business
													</Link>
													<Link
														className='dropdown-item colll mb-5'
														to='/schedule-demo'
													>
														Developers
													</Link>

													{/* <!-- Heading --> */}
													<h6 className='dropdown-header'>Apps</h6>

													{/* <!-- List --> */}
													<Link
														className='dropdown-item colll'
														to='/schedule-demo'
													>
														Premium Apps
													</Link>
													<Link
														className='dropdown-item colll'
														to='/schedule-demo'
													>
														Standard Apps
													</Link>
												</div>
												<div className='col-6'>
													{/* <!-- Heading --> */}
													<h6 className='dropdown-header'>Applications</h6>

													{/* <!-- List --> */}
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Support Tickets
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Expense Tracking
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Invoice Generator
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Scheduling App
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Learning Management
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														Online Assessment
													</a>
													<a
														className='dropdown-item colll'
														href='/#solutions'
														data-scroll='{"offset": 0}'
													>
														SalesBook{' '}
														<span className='h6 text-uppercase text-primary'>
															(new)
														</span>
													</a>
												</div>
											</div>{' '}
											{/* <!-- / .row --> */}
										</div>
									</div>
								</div>{' '}
								{/* <!-- / .row --> */}
							</div>
						</li>
						<li className='nav-item'>
							<a
								className='nav-link colll'
								href='/#solutions'
								data-scroll='{"offset": 0}'
							>
								Our Solutions
							</a>
						</li>
						<li className='nav-item'>
							<Link className='nav-link colll' to='pricing'>
								Pricing
							</Link>
						</li>
						<li className='nav-item'>
							<Link className='nav-link colll' to='/schedule-demo'>
								Developers
							</Link>
						</li>
						<li className='nav-item'>
							<Link className='nav-link colll' to='/schedule-demo'>
								Help Center
							</Link>
						</li>
					</ul>
					{/* <!-- Button --> */}
					{/* <a
						href='https://auth.singlelogin.io/auth/invite/eyJpbnZpdGVfbGluayI6dHJ1ZSwiYnVzaW5lc3NfaWQiOjIzLCJleHBpcmF0aW9uX2RhdGUiOjE2NzI0NjI4MDAsInJvbGVfaWQiOiI5IiwicHJvZmlsZV9pZCI6IjM2IiwiZGVwYXJ0bWVudF9pZCI6IjE2IiwidXNhZ2UiOiIxMDAwMCIsInBheW1lbnRyZXF1aXJlZCI6dHJ1ZX0='
						className='navbar-btn btn btn-sm btn-primary lift mx-2'
					>
						Register
					</a>{' '} */}

					<a
						href='https://auth.singlelogin.io'
						className='navbar-btn btn btn-sm btn-primary lift mx-2'
					>
						Sign In
					</a>
				</div>
			</div>
		</nav>
	)
}
