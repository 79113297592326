import React from 'react'
import {Form} from '../components/Form'

export function DemoPage() {
    return (
      <>
        {/* <!-- CTA --> */}
        <section className="py-8 py-md-11 bg-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center" id="demo">

              {/* <!-- Badge --> */}
              {/* <span className="badge rounded-pill bg-gray-700-soft mb-4">
                <span className="h6 fw-bold text-uppercase">Get started</span>
              </span> */}

              {/* <!-- Heading --> */}
              <h1 className="display-4 text-white">
                Save Time and Stay in Complete Control of your Business.
              </h1>

              {/* <!-- Text --> */}
              <p className="fs-lg text-muted mb-6 mb-md-8">
                Our App is fast, simple and ready for use.
              </p>

                    {/* <!-- Card --> */}
                    <div className="card card-border border-primary shadow-light-lg" data-jarallax-element="-40">
                      <div className="card-body">
                        <div className="row justify-content-center">
                          <div className="col-12 col-xl-11">
        
                            {/* <!-- Image --> */}
                            <div className="text-center">
                              <img src="assets/img/illustrations/illustration-1.png" alt="..." className="img-fluid mb-3" style={{width: "200px"}} />
                            </div>
        
                            {/* <!-- Heading --> */}
                            <h2 className="fw-bold text-center mb-1">
                              Schedule a demo with us
                            </h2>
        
                            {/* <!-- Text --> */}
                            <p className="fs-lg text-center text-muted mb-6 mb-md-8">
                              We can help you accelerate business productivity.
                            </p>
        
                            {/* <!-- Form --> */}
                            <Form />
        
                          </div>
                        </div> {/* <!-- / .row --> */}
                      </div>
                    </div>

            </div>
          </div> {/* <!-- / .row --> */}
        </div> {/* <!-- / .container --> */}
      </section>
      </>
    )
}