import React, {useState} from 'react'

export function PricingPage() {
  const [annual, setAnnual] = useState(false)
  const changePrice = (e) => {
    e.target.checked ? setAnnual(true) : setAnnual(false)
  }
    return (
      <>
      {/* <!-- WELCOME --> */}
      <section className="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary">
  
          {/* <!-- Shape --> */}
          <div className="shape shape-blur-3 text-white">
            <svg viewBox="0 0 1738 487" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z" fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="0" y1="0" x2="1049.98" y2="912.68" gradientUnits="userSpaceOnUse"><stop stopColor="currentColor" stopOpacity=".075"/><stop offset="1" stopColor="currentColor" stopOpacity="0"/></linearGradient></defs></svg>      </div>
    
          {/* <!-- Content --> */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">
    
                {/* <!-- Heading --> */}
                <h1 className="display-2 text-white">
                  Simple pricing for all.
                </h1>
    
                {/* <!-- Text --> */}
                <p className="lead text-white-80 mb-6 mb-md-8">
                 Single Login provides you with everything you need to secure your applications and customer relationship data at an affordable price. Any subscription bundle gives you access to all Standard Applications.  
                </p>
    
                {/* <!-- Form --> */}
                <form className="d-flex align-items-center justify-content-center mb-7 mb-md-9">
    
                  {/* <!-- Label --> */}
                  <span className="text-white-80">
                    Standard
                  </span>
    
                  {/* <!-- Switch --> */}
                  <div className="form-check form-check-dark form-switch mx-3">
                    <input className="form-check-input" type="checkbox" id="billingSwitch" data-toggle="price" data-target=".price" onChange={changePrice} />
                  </div>
    
                  {/* <!-- Label --> */}
                  <span className="text-white-80">
                    Premium
                  </span>
    
                </form>
    
              </div>
            </div> {/* <!-- / .row --> */}
          </div> {/* <!-- / .container --> */}
    
        </section>
    
        {/* <!-- SHAPE --> */}
        <div className="position-relative">
          <div className="shape shape-bottom shape-fluid-x text-light">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>      </div>
        </div>
    
        {/* <!-- PRICING --> */}
        <section className="mt-n8 mt-md-n14">
          <div className="container">
            <div className="row gx-4">
              <div className="col-12 col-md-4">
    
                {/* <!-- Card --> */}
                <div className="card shadow-lg mb-6 mb-md-0">
                  <div className="card-body">
    
                    {/* <!-- Preheading --> */}
                    <div className="text-center mb-3">
                      <span className="badge rounded-pill bg-primary-soft">
                        <span className="h6 text-uppercase">Developer</span>
                      </span>
                    </div>
    
                    {/* <!-- Price --> */}
                    <div className="d-flex justify-content-center">
                      <span className="h2 mb-0 mt-2">$</span>
                      <span className="price display-2 mb-0" data-annual="5" data-monthly="3">{annual ? 0 * 12 : 0}</span>
                      <span className="h2 align-self-end mb-1">{annual ? '/mo' : '/mo'}</span>
                    </div>
    
                    {/* <!-- Text --> */}
                    <p className="text-center text-muted mb-5">
                      per user
                    </p>
    
                    {/* <!-- List --> */}
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                         All Standard Applications.
                      </p>
    
                    </div>
                                      <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                      Integration API access.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p className="mb-5">
                      Free Training.
                      </p>
    
                    </div>
               
    

                    {/* <!-- Button --> */}
                    <a href="#!" className="btn w-100 btn-primary-soft">
                      Start for free <i className="fe fe-arrow-right ms-3"></i>
                    </a>
    
                  </div>
                </div>
    
              </div>
              <div className="col-12 col-md-4">
    
                {/* <!-- Card --> */}
                <div className="card shadow-lg mb-6 mb-md-0">
                  <div className="card-body">
    
                    {/* <!-- Preheading --> */}
                    <div className="text-center mb-3">
                      <span className="badge rounded-pill bg-primary-soft">
                        <span className="h6 text-uppercase">Business</span>
                      </span>
                    </div>
    
                    {/* <!-- Price --> */}
                    <div className="d-flex justify-content-center">
                      <span className="h2 mb-0 mt-2">$</span>
                      <span className="price display-2 mb-0" data-annual="5" data-monthly="5">{annual ? 5 : 3}</span>
                      <span className="h2 align-self-end mb-1">{annual ? '/mo' : '/mo'}</span>
                    </div>
    
                    {/* <!-- Text --> */}
                    <p className="text-center text-muted mb-5">
                      per user
                    </p>
    
                    {/* <!-- List --> */}
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        All Standard Applications.
                      </p>
    
                    </div>
                           <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        {annual ? '4' : '0'} Premium Applications.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        {annual ? '' : 'No'} Integration API access.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Free Support & Upgrades.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Flexible PAYG Option.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p className="mb-5">
                        No minimum users.
                      </p>
    
                    </div>
    
                    {/* <!-- Button --> */}
                    <a href="#!" className="btn w-100 btn-primary">
                      Start with {annual ? 'Premium' : 'Standard'} <i className="fe fe-arrow-right ms-3"></i>
                    </a>
    
                  </div>
                </div>
    
              </div>
              <div className="col-12 col-md-4">
    
                {/* <!-- Card --> */}
                <div className="card shadow-lg mb-md-0">
                  <div className="card-body">
    
                    {/* <!-- Preheading --> */}
                    <div className="text-center mb-3">
                      <span className="badge rounded-pill bg-primary-soft">
                        <span className="h6 text-uppercase">Enterprise</span>
                      </span>
                    </div>
    
                    {/* <!-- Price --> */}
                    <div className="d-flex justify-content-center">
                      <span className="h2 mb-0 mt-2">$</span>
                      <span className="price display-2 mb-0" data-annual="2" data-monthly="1">{annual ? 2 : 1}</span>
                      <span className="h2 align-self-end mb-1">{annual ? '/mo' : '/mo'}</span>
                    </div>
    
                    {/* <!-- Text --> */}
                    <p className="text-center text-muted mb-5">
                      per user
                    </p>
    
                    {/* <!-- List --> */}
                                       <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        All Standard Applications.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Dedicated Server.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Dedicated Database.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Dedicated Domain.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Integration API access.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Free Support & Upgrades.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Flexible PAYG Option.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p>
                        Guaranteed 99.99% uptime.
                      </p>
    
                    </div>
                    <div className="d-flex">
    
                      {/* <!-- Badge --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>
    
                      {/* <!-- Text --> */}
                      <p className="mb-5">
                        Minimum User Applicable.
                      </p>
    
                    </div>
    
                    {/* <!-- Button --> */}
                    <a href="#!" className="btn w-100 btn-primary-soft">
                      Start with Enterprise <i className="fe fe-arrow-right ms-3"></i>
                    </a>
    
                  </div>
                </div>
    
              </div>
            </div> {/* <!-- / .row --> */}
          </div> {/* <!-- / .container --> */}
        </section>
    
        {/* <!-- SHAPE --> */}
        <div className="position-relative">
          <div className="shape shape-bottom shape-fluid-x text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>      </div>
        </div>
    
        {/* <!-- FAQ --> */}
        <section className="py-8 py-md-11 bg-white border-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-md-8 text-center">
    
                {/* <!-- Badge --> */}
                <span className="badge rounded-pill bg-primary-desat-soft mb-3">
                  <span className="h6 text-uppercase">FAQ</span>
                </span>
    
                {/* <!-- Heading --> */}
                <h2>
                  Frequently asked questions.
                </h2>
    
                {/* <!-- Text --> */}
                <p className="fs-lg text-muted mb-7 mb-md-9">
                  Let us help answer the most common questions you might have.
                </p>
    
              </div>
            </div> {/* <!-- / .row --> */}
            <div className="row">
              <div className="col-12 col-md-6">
    
                {/* <!-- Heading --> */}
                <h3>
                  Can I use SingleLogin for my external clients?
                </h3>
    
                {/* <!-- Text --> */}
                <p className="text-gray-800 mb-6 mb-md-8">
Absolutely. The SingleLogin license allows you to build an application for personal or Business use or for a client. All you need is a User Licence to authorize your application or any user that requires access.                  
                </p>
    
                {/* <!-- Heading --> */}
                <h3>
                   Do I get free updates?
                </h3>
    
                {/* <!-- Text --> */}
                <p className="text-gray-800 mb-6 mb-md-8">
                      Yes. We automatically update all our applications with each deployed update, and constantly add new applications, pages, and features to our server.
                </p>
              </div>
              <div className="col-12 col-md-6">
    
                {/* <!-- Heading --> */}
                <h3>
                  Does it work with other programming languages?
                </h3>
    
                {/* <!-- Text --> */}
                <p className="text-gray-800 mb-6 mb-md-8">
                      Yes. SingleLogin is an authentication and authorization tool that provides a token after authentication. You can develop or integrate your application around this principle. If you want to enable deeper customization, you can integrate it into your assets pipeline or build processes.
                </p>
    
                {/* <!-- Heading --> */}
                <h3>
                  Is there a money back guarantee?
                </h3>
    
                {/* <!-- Text --> */}
                <p className="text-gray-800 mb-6 mb-md-8">
                      No! Our business is licence-driven. If you do not like our service, all you need to do is stop licence renewal or subscription. We have a 30 day free trial for all new businesses.
                </p>
              </div>
            </div> {/* <!-- / .row --> */}
          </div> {/* <!-- / .container --> */}
        </section>
      </>
    )
}